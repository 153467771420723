import { useQuery } from '@tanstack/vue-query'

export const useBookedTransactions = (bookedId) => {
    const { execute } = useApi('/api/booked-transactions', 'GET')

    const { data, isFetching, refetch } = useQuery({
        queryKey: ['booked-transactions', bookedId],
        queryFn: () =>
            execute({
                queryParams: {
                    all: true,
                    filter: {
                        booked_id: bookedId,
                    },
                },
            }),
        staleTime: Infinity,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
    })

    const transactions = computed(() => data.value || [])

    return {
        transactions,
        refetch,
        isFetching,
    }
}
